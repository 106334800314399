import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { defaultLayoutComponents } from '~/pages/layout';
import { RouteKeys } from '~/router/routes.domain';
import { useRolesStore } from '~/stores';

export const ConfigPfsRoute: RouteRecordRaw = {
	path: 'pfs',
	name: RouteKeys.CONFIG_PFS,
	components: {
		default: () => import('./ConfigView.vue'),
		...defaultLayoutComponents
	},
	meta: {
		requiresAuth: true,
		isForbidden: () => {
			const { isSuperAdmin } = storeToRefs(useRolesStore());
			return computed(() => !isSuperAdmin.value);
		},
		label: 'configuration.pfs.navigation',
		isSubEntry: true,
		order: 3
	}
};

export const ConfigUsersRoute: RouteRecordRaw = {
	path: 'users',
	name: RouteKeys.CONFIG_USER,
	components: {
		default: () => import('./ConfigUsersView.vue'),
		...defaultLayoutComponents
	},
	meta: {
		requiresAuth: true,
		isForbidden: () => {
			const { isAdmin } = storeToRefs(useRolesStore());
			return computed(() => !isAdmin.value);
		},
		label: 'configuration.users.navigation',
		isSubEntry: true,
		order: 3
	}
};

export const ConfigUserGroupsRoute: RouteRecordRaw = {
	path: 'user-groups',
	name: RouteKeys.CONFIG_USER_GROUPS,
	components: {
		default: () => import('./ConfigUserGroupsView.vue'),
		...defaultLayoutComponents
	},
	meta: {
		requiresAuth: true,
		isForbidden: () => {
			const { isAdmin } = storeToRefs(useRolesStore());
			return computed(() => !isAdmin.value);
		},
		label: 'configuration.user-groups.navigation',
		isSubEntry: true,
		order: 4
	}
};

export const ConfigUserGroupEditRoute: RouteRecordRaw = {
	path: 'user-groups/edit/:groupId',
	name: RouteKeys.CONFIG_USER_GROUPS_EDIT,
	components: {
		default: () => import('./ConfigUserGroupEditView.vue'),
		...defaultLayoutComponents
	},
	meta: {
		requiresAuth: true,
		isForbidden: () => {
			const { isAdmin } = storeToRefs(useRolesStore());
			return computed(() => !isAdmin.value);
		}
	}
};

export const ConfigPasswordRoute: RouteRecordRaw = {
	path: 'password',
	name: RouteKeys.CONFIG_PASSWORD,
	components: {
		default: () => import('./ConfigPasswordView.vue'),
		...defaultLayoutComponents
	},
	meta: {
		requiresAuth: true,
		isForbidden: () => {
			const { isSuperAdmin } = storeToRefs(useRolesStore());
			return computed(() => !isSuperAdmin.value);
		},
		label: 'configuration.services.navigation',
		isSubEntry: true,
		order: 5
	}
};

export const ClipBoardRoute: RouteRecordRaw = {
	path: 'clip-board',
	name: RouteKeys.CONFIG_CLIP_BOARD,
	components: {
		default: () => import('./DebugClipboardView.vue'),
		...defaultLayoutComponents
	},
	meta: {
		requiresAuth: true,
		isForbidden: () => {
			const { isSuperAdmin } = storeToRefs(useRolesStore());
			return computed(() => !isSuperAdmin.value);
		},
		label: 'configuration.clipboard.navigation',
		isSubEntry: true,
		order: 8
	}
};

export const ConfigRoute: RouteRecordRaw = {
	path: '/config',
	name: RouteKeys.CONFIG,
	components: {
		default: () => import('./config.vue'),
		...defaultLayoutComponents
	},
	redirect: ConfigUsersRoute,
	children: [ConfigUsersRoute, ConfigUserGroupsRoute, ConfigPfsRoute, ConfigPasswordRoute, ConfigUserGroupEditRoute, ClipBoardRoute],
	meta: {
		isNavEntry: true,
		isForbidden: () => {
			const { isAdmin } = storeToRefs(useRolesStore());
			return computed(() => !isAdmin.value);
		},
		label: 'configuration.navigation',
		icon: 'fa-gears',
		requiresAuth: true,
		order: 1
	}
};

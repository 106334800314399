export type SquashLogin = {
	login: string;
	password: string;
};

export type PostHogConfig = {
	pat: string;
	projectId: number;
	insightId: number;
};

export type ConfigServiceDto = {
	state: boolean;
	name: string;
};

export type UserConfigDTO = {
	id: number;
	login: string;
	rights: QA2Right[];
	active: boolean;
};

export enum QA2Right {
	SUPER_ADMIN = 'SUPER_ADMIN',
	ADMIN = 'ADMIN',
	VISITOR = 'VISITOR',
	USER = 'USER',
	BEGINNER = 'BEGINNER',
	SUPPORT = 'SUPPORT',
	PM = 'PM',
	QA = 'QA',
	API = 'API'
}

export const QA2RightsFull: QA2Right[] = [
	QA2Right.SUPER_ADMIN,
	QA2Right.API,
	QA2Right.ADMIN,
	QA2Right.VISITOR,
	QA2Right.USER,
	QA2Right.BEGINNER,
	QA2Right.PM,
	QA2Right.QA,
	QA2Right.SUPPORT
];
export const QA2Rights: QA2Right[] = [QA2Right.VISITOR, QA2Right.USER, QA2Right.BEGINNER, QA2Right.PM, QA2Right.QA, QA2Right.SUPPORT];

export function getUnusedRights(rights: QA2Right[]) {
	return QA2Rights.filter(x => !rights.includes(x));
}

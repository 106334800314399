import { createI18n } from 'vue-i18n';

import en from '~/i18n/en.json';
import fr from '~/i18n/fr.json';

const datetimeCustomFormats = {
	fr: {
		weekday: {
			weekday: 'long',
			day: '2-digit',
			month: 'long'
		},
		weekdayShort: {
			day: 'numeric',
			weekday: 'short',
			month: 'short'
		},
		dayShort: {
			weekday: 'short'
		},
		long: {
			day: '2-digit',
			month: 'long',
			year: 'numeric'
		},
		longTime: {
			day: '2-digit',
			month: 'long',
			year: 'numeric',
			hour: 'numeric',
			minute: 'numeric'
		},
		short: {
			year: 'numeric',
			month: 'numeric',
			day: 'numeric'
		},
		shortTime: {
			year: 'numeric',
			month: 'numeric',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric'
		}
	},
	en: {
		weekday: {
			weekday: 'long',
			day: '2-digit',
			month: 'long'
		},
		weekdayShort: {
			day: 'numeric',
			weekday: 'short',
			month: 'short'
		},
		dayShort: {
			weekday: 'short'
		},
		long: {
			day: '2-digit',
			month: 'long',
			year: 'numeric'
		},
		longTime: {
			day: '2-digit',
			month: 'long',
			year: 'numeric',
			hour: 'numeric',
			minute: 'numeric'
		},
		short: {
			year: 'numeric',
			month: 'numeric',
			day: 'numeric'
		},
		shortTime: {
			year: 'numeric',
			month: 'numeric',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric'
		}
	}
};

const i18n = createI18n({
	datetimeFormats: datetimeCustomFormats,
	fallbackLocale: 'fr', // set fallback locale
	globalInjection: true,
	legacy: true, // use composition API
	locale: 'fr', // set locale
	messages: {
		en,
		fr
	}
});

export { i18n };

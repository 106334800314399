import { FieldValidationMetaInfo, localize, setLocale } from '@vee-validate/i18n';
import en from '@vee-validate/i18n/dist/locale/en.json';
import fr from '@vee-validate/i18n/dist/locale/fr.json';
import { digits, email, length, max, min, numeric, regex, required, url } from '@vee-validate/rules';
import { DateTime } from 'luxon';
import { configure, defineRule } from 'vee-validate';
import { useI18n } from 'vue-i18n';

import { ISODateString, Optional } from '@silae/helpers';
import { B2C_PASSWORD_REGEX, isValidBirthDate, parseMoula } from '~/utils';

import {
	isValidBic,
	isValidFrenchSSN,
	isValidIban,
	matchBirthdateToFrenchSSN,
	matchDepartmentOfBirthToFrenchSSN
} from './vee-validate.utils';

export type VeeValidateConfiguration = {
	locale: string;
};

const DEFAULT_CONFIG = {
	locale: 'fr'
};

type ValidationGenerateMessage = (ctx: FieldValidationMetaInfo) => string;

export const updateVeeValidateConfig = (config: VeeValidateConfiguration = DEFAULT_CONFIG) => {
	const conf = { ...DEFAULT_CONFIG, ...config };
	setLocale(conf?.locale);
};

export function useVeeValidateI18n() {
	const { t } = useI18n();

	/* localization for error messages, see: https://vee-validate.logaretm.com/v4/guide/i18n/ */
	/* french locale messages can be found here : https://www.npmjs.com/package/@vee-validate/i18n?activeTab=code */
	const simpleErrorMessageGenerator = localize({
		en: {
			...en,
			messages: {
				...en.messages
			}
		},
		fr: {
			...fr,
			messages: {
				...fr.messages
			}
		}
	});

	const complexErrorMessageGenerator: ValidationGenerateMessage = (ctx: FieldValidationMetaInfo) => {
		if (ctx.rule?.name === 'before' || ctx.rule?.name === 'beforeOffset') {
			const max: ISODateString = (ctx.rule?.params as any)?.[0];
			const daysOffset: number = (ctx.rule?.params as any)?.[1] ?? 0;

			if (max != null) {
				const maxDate = DateTime.fromISO(max).plus({ days: daysOffset }).toLocaleString(DateTime.DATE_SHORT);
				return t('common.rules.before', { date: maxDate });
			}
		}

		if (ctx.rule?.name === 'after') {
			const max: ISODateString = (ctx.rule?.params as any)?.[0];
			if (max != null) {
				const maxDate = DateTime.fromISO(max).toLocaleString(DateTime.DATE_SHORT);
				return t('common.rules.after', { date: maxDate });
			}
		}

		if (ctx.rule?.name === 'positive_moula') {
			const decimalPlace: number = (ctx.rule?.params as any)?.[0];
			if (decimalPlace != undefined) {
				return t('common.rules.positive_moula', { decimalPlace: decimalPlace });
			}
		}

		const ruleKey = `common.rules.${ctx.rule?.name}`;

		// Make sure we have an object (not an array) with the parameters
		const ruleParams = Array.isArray(ctx.rule?.params) ? {} : ctx.rule?.params || {};

		// Return the translation if it exists, or use the default message generator
		const translation = t(ruleKey, ruleParams);
		return translation === ruleKey ? simpleErrorMessageGenerator(ctx) : translation;
	};

	configure({
		generateMessage: complexErrorMessageGenerator
	});
}

export const setupVeeValidate = (config: VeeValidateConfiguration = DEFAULT_CONFIG) => {
	/* import rules from vee validate global validators, see: https://vee-validate.logaretm.com/v4/guide/global-validators/#vee-validaterules */
	/* to import more rules : https://vee-validate.logaretm.com/v4/guide/global-validators/#available-rules */
	defineRule('email', email);
	defineRule('digits', digits);
	defineRule('required', required);
	defineRule('min', min);
	defineRule('max', max);
	defineRule('url', url);
	defineRule('regex', regex);
	defineRule('numeric', numeric);
	defineRule('length', length);

	// Custom rules
	defineRule('no_leading_or_trailing_spaces', (value: string) => value.trim() === value);
	defineRule('password_different', (value: string, target: string) => String(value) !== String(target));
	defineRule('password_confirmed', (value: string, target: string) => String(value) === String(target));
	defineRule('b2c_password', (value: string) => B2C_PASSWORD_REGEX.test(value));
	defineRule('at_least_one_email', (value: string, [target]: [string]) => !!(value || target));
	defineRule('positive_moula', (value: string | number, [decimalPlaces]: [number]) => {
		const valueAsString = value?.toString() || '';
		return !valueAsString || parseMoula(valueAsString, decimalPlaces) > 0;
	});
	defineRule('after', (value: ISODateString, [candidate]: [ISODateString]) => {
		if (!value || !candidate) return true;
		return value > candidate;
	});
	defineRule('before', (value: ISODateString, [candidate]: [ISODateString]) => {
		if (!value || !candidate) return true;
		return value < candidate;
	});
	defineRule('beforeOffset', (candidate: ISODateString, [max, daysOffset]: [ISODateString, number]) => {
		if (!candidate || !max) return true;
		const maxDate = DateTime.fromISO(max).plus({ days: daysOffset });
		const candidateDate = DateTime.fromISO(candidate);
		return candidateDate < maxDate;
	});
	defineRule('validate_birthdate', (value: ISODateString) => isValidBirthDate(value));
	defineRule('phone_number', (value: string) => !value || /^0\d{9}$/.test(value));
	defineRule('french_ssn', (value: string) => isValidFrenchSSN(value));
	defineRule('french_ssn_match_birthdate', (value: string, [birthdate]: [ISODateString]) => {
		return matchBirthdateToFrenchSSN(value, birthdate);
	});
	defineRule('french_ssn_match_department_of_birth', (value: string, [department]: [string]) => {
		return matchDepartmentOfBirthToFrenchSSN(value, department);
	});
	defineRule('iban', (value: string) => {
		return isValidIban(value);
	});
	defineRule('bic', (value: string, [iban]: [Optional<string>]) => {
		return isValidBic(value, iban);
	});

	updateVeeValidateConfig(config);
};

export const RouteKeys = {
	ACCOUNT: 'account',
	ACCOUNT_SECURITY: 'account.security',
	CONNECTION: 'connection',
	HOME: 'home',
	NOT_FOUND: '404',
	CONFIG: 'config',
	CONFIG_PFS: 'config-pfs',
	CONFIG_USER: 'config-users',
	CONFIG_USER_GROUPS: 'config-user-groups',
	CONFIG_USER_GROUPS_EDIT: 'config-user-groups-edit',
	CONFIG_PASSWORD: 'config-password',
	CONFIG_CLIP_BOARD: 'config-clip-board',
	METRIC: 'metric',
	METRIC_TASK: 'metric-task',
	METRIC_TASK_USAGE: 'metric-task-usage',
	METRIC_FOLDER: 'metric-folder',
	METRIC_PAGE_VIEW: 'metric-page-view',
	METRIC_TASK_RUN: 'metric-task-run',
	PAY_GEN: 'pay-gen-home',
	PAY_GEN_ESTABLISHMENT: 'pay-gen-establishment',
	PAY_GEN_EMPLOYEE: 'pay-gen-employee',
	PAY_GEN_TEAM: 'pay-gen-team',
	PAY_GEN_MULTI: 'pay-gen-multi',
	PAY_GEN_SINGLE: 'pay-gen',
	PAY_GEN_SINGLE_COMPANY_ID: 'pay-gen-company-id',
	SIGN_IN: 'sign-in',
	QA2_CONFIG: 'qa-config',
	QA2_CONFIG_PATTERN: 'qa-config-pattern',
	QA2_TEST_CASE: 'qa-test-case',
	QA2_TEST_CASE_HOME: 'qa-test-case-home',
	QA_TEST_CAMPAIGN: 'qa-test-campaign',
	QA_TEST_CAMPAIGN_DETAILS: 'qa-test-campaign-details',
	QA_TEST_CASE_EXECUTION_DETAILS: 'qa-test-case-execution-details',
	QA_TEST_STEP_EXECUTION_DETAILS: 'qa-test-step-execution-details',
	QA_HOME: 'qa-home',
	QA_AUTO_HOME: 'qa-auto-home',
	QA_AUTO_CRON: 'qa-auto-cron',
	QA_AUTO_CRON_CONFIG: 'qa-auto-cron-config',
	QA_AUTO_FOLDER_ERROR: 'qa-auto-folder-error',
	PM_HOME: 'pm-home',
	RELEASE_NOTE: 'pm-release-note',
	DOC: 'doc',
	PM_REQUIREMENTS_TABLE: 'pm-requirements',
	PM_AZURE_SPRINT: 'pm-azure-sprint',
	PM_ROADMAP: 'pm-roadmap',
	PM_ROADMAP_EDIT: 'pm-roadmap-edit',
	PM_ROADMAP_GOAL_EDIT: 'pm-roadmap-goal-edit',
	PM_ROADMAP_DETAILS: 'pm-roadmap-details',
	PM_AZURE_SPRINT_DETAILS: 'pm-azure-sprint-details',
	DOC_TABLE_ANALYSIS_ITEMS: 'doc-table-analysis-items',
	DOC_FOLDER: 'doc-folder',
	DOC_REQUIREMENT: 'doc-requirement',
	DOC_REQUIREMENT_RULES: 'doc-requirement-rules',
	DOC_TEST_CASE: 'doc-test-case',
	DOC_NOT_FOUND: 'doc-not-found',
	UPDATE_PASSWORD: 'update-password',
	UPDATE_PASSWORD_GENERATE_CODE: 'update-password.generate-code',
	UPDATE_PASSWORD_RESET: 'update-password.reset',
	UPDATE_PASSWORD_RESET_NO_PARAMETER: 'update-password.reset.np',
	SUPPORT: 'support'
};

import { DefaultProductBoardObject, PM_PRODUCT_BOARD_URL, useBackendHttpService } from '~/api';

export function getProductBoardObjectives(parentId: string) {
	const { http } = useBackendHttpService();
	return http.get<DefaultProductBoardObject[]>(`${PM_PRODUCT_BOARD_URL}/objectives?parent.id=${parentId}`);
}

export function getProductBoardMasterObjectives() {
	const { http } = useBackendHttpService();
	return http.get<DefaultProductBoardObject[]>(`${PM_PRODUCT_BOARD_URL}/master-objectives`);
}

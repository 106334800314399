import { QA_AUTO, QA_AUTO_ERROR, useDefaultApi } from '~/api/defs';
import { FolderGenerationCronDTO, FolderGenerationErrorDTO, QaAutoFolderDTO } from './qa-auto-dto';
import { PaginationData, useBackendHttpService } from '~/api';
import { Observable } from 'rxjs';
import { TaskDTO } from '~/types/config/task';

export const {
	create: createQaAutoFolderCron,
	update: updateQaAutoFolderCron,
	getById: getByIdQaAutoFolderCron,
	getPage: getPageQaAutoFolderCron,
	deleteObject: deleteQaAutoFolderCron
} = useDefaultApi<FolderGenerationCronDTO>(QA_AUTO);

export const {
	getById: getByIdQaAutoFolderError,
	getPage: getPageQaAutoFolderError,
	deleteObject: deleteQaAutoFolderError
} = useDefaultApi<FolderGenerationErrorDTO>(QA_AUTO_ERROR);

export function cleanAllQaAutoFolderError() {
	const { http } = useBackendHttpService();
	return http.delete(`${QA_AUTO_ERROR}/clean-all`);
}

export function getAllForCron(
	cronId: number,
	pageNumber: number,
	pageSize: number,
	folderNumber: string | undefined = undefined,
	usedOnly: boolean | undefined = undefined): Observable<PaginationData<QaAutoFolderDTO>> {
	const { http } = useBackendHttpService();
	if (folderNumber) {
		if (usedOnly) {
			return http.getPage<QaAutoFolderDTO>(`${QA_AUTO}/cron/${cronId}/details?folder-number=${encodeURIComponent(folderNumber)}&used-only=${usedOnly}&sort=id,desc`, pageNumber, pageSize);
		} else {
			return http.getPage<QaAutoFolderDTO>(`${QA_AUTO}/cron/${cronId}/details?folder-number=${encodeURIComponent(folderNumber)}&sort=id,desc`, pageNumber, pageSize);
		}

	} else {
		if (usedOnly) {
			return http.getPage<QaAutoFolderDTO>(`${QA_AUTO}/cron/${cronId}/details?used-only=${usedOnly}&sort=id,desc`, pageNumber, pageSize);
		} else {
			return http.getPage<QaAutoFolderDTO>(`${QA_AUTO}/cron/${cronId}/details?sort=id,desc`, pageNumber, pageSize);
		}

	}

}

export function cleanUsedFolder(cronId: number, deleteInMsgp: boolean = false, count: number | undefined = undefined) {
	const { http } = useBackendHttpService();
	if (count) {
		return http.delete<TaskDTO>(`${QA_AUTO}/cron/${cronId}/clean-used?clean-msgp=${deleteInMsgp}&count=${count}`);
	} else {
		return http.delete<TaskDTO>(`${QA_AUTO}/cron/${cronId}/clean-used?clean-msgp=${deleteInMsgp}`);
	}

}

export function cleanAllFolder(cronId: number, deleteInMsgp: boolean = false, count: number | undefined = undefined) {
	const { http } = useBackendHttpService();
	if (count) {
		return http.delete<TaskDTO>(`${QA_AUTO}/cron/${cronId}/clean-all?clean-msgp=${deleteInMsgp}&count=${count}`);
	} else {
		return http.delete<TaskDTO>(`${QA_AUTO}/cron/${cronId}/clean-all?clean-msgp=${deleteInMsgp}`);
	}
}

export function patchCronActivation(cronId: number, newActiveValue: boolean) {
	const { http } = useBackendHttpService();
	return http.patch<FolderGenerationCronDTO>(`${QA_AUTO}/cron/${cronId}/active?value=${newActiveValue}`);
}

export function getAllFolderFormatValue() {
	const { http } = useBackendHttpService();
	return http.get<string[]>(`${QA_AUTO}/all-folder-format`);
}

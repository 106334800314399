import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { defaultLayoutComponents } from '~/pages/layout';
import { QaAutoRoute } from '~/pages/qa/qaauto/qa-auto-routes';
import {
	qaTestCampaignDetailsRoute,
	qaTestCampaignHomeRoute,
	qaTestCaseExecutionRoute,
	qaTestStepExecutionRoute
} from '~/pages/qa/test-execution/test-execution-routes';
import { QaConfigRoute } from '~/pages/qa/testcase/config/qa.config.route';
import { qaTestCaseHomeRoute } from '~/pages/qa/testcase/qa2-routes';
import { RouteKeys } from '~/router/routes.domain';
import { useRolesStore } from '~/stores';

export const QaHomeRoute: RouteRecordRaw = {
	path: '/qa',
	name: RouteKeys.QA_HOME,
	components: {
		default: () => import('./qa.vue'),
		...defaultLayoutComponents
	},
	redirect: qaTestCampaignHomeRoute,
	children: [
		qaTestCampaignHomeRoute,
		qaTestCaseHomeRoute,
		QaAutoRoute,
		QaConfigRoute,
		qaTestCampaignDetailsRoute,
		qaTestCaseExecutionRoute,
		qaTestStepExecutionRoute
	],
	meta: {
		isNavEntry: true,
		isForbidden: () => {
			const { isQA } = storeToRefs(useRolesStore());
			return computed(() => !isQA.value);
		},
		label: 'qa.navigation',
		icon: 'vial-circle-check',
		requiresAuth: true,
		order: 4
	}
};

import { defineStore } from 'pinia';
import { computed, ComputedRef, Ref, ref } from 'vue';
import { DefaultProductBoardObject, getProductBoardMasterObjectives } from '~/api';
import { Observable, tap } from 'rxjs';
import { useHttpCache } from '@silae/composables';
import { Clearable } from '../store.domain';

const singletonMasterObjectivesReferentialUniqueKey = 'master_objectives';

export type ProductBoardStore = Clearable & {
	fetchMasterObjectives$: (invalidate?: boolean) => Observable<Array<DefaultProductBoardObject>>;
	masterObjectives: ComputedRef<Array<DefaultProductBoardObject>>;
};

export const useProductBoardStore = defineStore<'productBoardStore', ProductBoardStore>('productBoardStore', () => {
	const { cache$: cacheMasterObjectives$, clearCache } = useHttpCache<string, Array<DefaultProductBoardObject>>();
	const _masterObjectives: Ref<Array<DefaultProductBoardObject>> = ref([]);
	const _fetchMasterObjectives$ = (): Observable<Array<DefaultProductBoardObject>> => {
		return cacheMasterObjectives$(
			singletonMasterObjectivesReferentialUniqueKey,
			getProductBoardMasterObjectives().pipe(tap(res => (_masterObjectives.value = res ?? [])))
		);
	};
	const clear = () => {
		clearCache();
	};

	return {
		clear,
		fetchMasterObjectives$: _fetchMasterObjectives$,
		masterObjectives: computed(() => _masterObjectives.value)
	};
});

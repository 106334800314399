import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { defaultLayoutComponents } from '~/pages/layout';
import { RouteKeys } from '~/router/routes.domain';
import { useRolesStore } from '~/stores';

export const TasksRoute: RouteRecordRaw = {
	path: 'tasks',
	name: RouteKeys.METRIC_TASK,
	components: {
		default: () => import('./MetricTasksView.vue'),
		...defaultLayoutComponents
	},
	meta: {
		requiresAuth: true,
		isForbidden: () => {
			const { isAdmin } = storeToRefs(useRolesStore());
			return computed(() => !isAdmin.value);
		},
		label: 'metric.tasks.navigation',
		isSubEntry: true,
		order: 3
	}
};

export const TaskUsageRoute: RouteRecordRaw = {
	path: 'task-usage',
	name: RouteKeys.METRIC_TASK_USAGE,
	components: {
		default: () => import('./MetricTaskUsageView.vue'),
		...defaultLayoutComponents
	},
	meta: {
		requiresAuth: true,
		isForbidden: () => {
			const { isAdmin } = storeToRefs(useRolesStore());
			return computed(() => !isAdmin.value);
		},
		label: 'metric.taskUsage.navigation',
		isSubEntry: true,
		order: 3
	}
};

export const MetricPageViewRoute: RouteRecordRaw = {
	path: 'page-view',
	name: RouteKeys.METRIC_PAGE_VIEW,
	components: {
		default: () => import('./MetricPageViewView.vue'),
		...defaultLayoutComponents
	},
	meta: {
		requiresAuth: true,
		isForbidden: () => {
			const { isAdmin } = storeToRefs(useRolesStore());
			return computed(() => !isAdmin.value);
		},
		label: 'metric.pageView.navigation',
		isSubEntry: true,
		order: 5
	}
};

export const MetricRoute: RouteRecordRaw = {
	path: '/metric',
	name: RouteKeys.METRIC,
	components: {
		default: () => import('./metric.vue'),
		...defaultLayoutComponents
	},
	redirect: TasksRoute,
	children: [TasksRoute, MetricPageViewRoute, TaskUsageRoute],
	meta: {
		isNavEntry: true,
		isForbidden: () => {
			const { isAdmin } = storeToRefs(useRolesStore());
			return computed(() => !isAdmin.value);
		},
		label: 'metric.navigation',
		icon: 'chart-column',
		requiresAuth: true,
		order: 2
	}
};

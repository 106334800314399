import dayjs, { QUnitType } from 'dayjs';
import { DateTime, DateTimeFormatOptions } from 'luxon';
import { LocaleOptions } from 'luxon/src/datetime';

import { ISODateString, ISODateTimeString } from '~/api';

// Custom date range type, instead of using v-calendar's SimpleDateRange (not exported properly)
export type DateRange = { start: Date; end: Date };

export const ISO_FORMAT = 'y-MM-dd'; // ex: 2024-04-01
export const PAY_PERIOD = 'LLLL yyyy'; // ex: avril 2024

export function ISODateTimeAsLocalDate(date: ISODateTimeString, format: DateTimeFormatOptions = DateTime.DATE_FULL): string {
	const dateTime = DateTime.fromISO(date.toString());
	return dateTime.toLocaleString(format);
}

export function ISODateAsLocalDate(ISO: ISODateString, format: DateTimeFormatOptions = DateTime.DATE_FULL): string {
	if (ISO == null) return null;
	return DateTime.fromISO(ISO).toLocaleString(format);
}

export function ISODateAsLocalDateTime(ISO: ISODateString): string {
	if (ISO == null) return null;
	const datetime = DateTime.fromISO(ISO);
	return `${datetime.toLocaleString(DateTime.DATE_SHORT)} ${datetime.toLocaleString(DateTime.TIME_SIMPLE)}`;
}

export function dateAsLocalDate(date: Date, format: DateTimeFormatOptions = DateTime.DATE_FULL): string {
	if (date == null) return null;
	return DateTime.fromJSDate(date).toLocaleString(format);
}

export function asFormattedDate(date: Date | string, format: string, opts?: LocaleOptions): string {
	if (date == null) return null;
	return DateTime.fromISO(date.toString()).toFormat(format, opts);
}

export function asUtcFormattedDate(date: Date | string, format: string, opts?: LocaleOptions): string {
	if (date == null) return null;
	return DateTime.fromISO(date.toString(), { zone: 'utc' }).toFormat(format, opts);
}

export function formatGMTDates(startDate: Date | string, endDate: Date | string, unit: QUnitType | string) {
	if (dayjs(startDate).diff(endDate, unit) == 0) {
		if (unit == 'months') {
			return `${asUtcFormattedDate(startDate, 'LLLL yyyy')}`;
		}
		if (unit == 'years') {
			return `${asUtcFormattedDate(startDate, 'yyyy')}`;
		}
		return `Le ${asUtcFormattedDate(startDate, 'DD')}`;
	} else {
		return `Du ${asUtcFormattedDate(startDate, 'DD')} au ${asUtcFormattedDate(endDate, 'DD')}`;
	}
}

export function dateEquals(startDate: Date, endDate: Date) {
	return dayjs(startDate).diff(endDate, 'minutes') == 0;
}

export function diffDate(startDate: Date, endDate: Date, unit: QUnitType) {
	return dayjs(startDate).diff(endDate, unit);
}

export function dateAsISODate(date: Date): ISODateString {
	if (date == null) return null;
	return DateTime.fromJSDate(date).toISODate();
}

export function splitHoursAndMinutes(hours: number): { hours: number; minutes: string } {
	if (hours == null) return null;
	const totalMinutes = Math.round(hours * 60);
	const hoursPart = Math.floor(totalMinutes / 60);
	const minutesPart = String(totalMinutes % 60).padStart(2, '0');

	return { hours: hoursPart, minutes: minutesPart };
}

export function getDateWithoutTimezone(date: Date): Date {
	if (date == null) {
		return;
	}

	const localTimezoneOffset = date.getTimezoneOffset();
	return new Date(date.getTime() - localTimezoneOffset * 60 * 1000);
}

export function isSameDay(d1: Date, d2: Date): boolean {
	return d1?.getFullYear() === d2?.getFullYear() && d1?.getMonth() === d2?.getMonth() && d1?.getDate() === d2?.getDate();
}

export function dateBetween(startDate: Date, endDate: Date) {
	const diff = dayjs(startDate).diff(endDate, 'hours');
	const random = Math.floor(Math.random() * diff);
	return dayjs(startDate).add(random, 'hours').toDate();
}

export function toDate(value: Date | string | undefined): Date {
	if (value === undefined) {
		return new Date();
	}
	return new Date(value);
}

export function monthLastDay(date: Date): Date {
	return dayjs(date).endOf('month').set('hour', 12).toDate();
}

export function monthFirstDay(date: Date): Date {
	return dayjs(date).startOf('month').set('hour', 12).toDate();
}

export const DAY_MILLIS = 1000 * 60 * 60 * 24;

export const MONTH_MILLIS = 30 * DAY_MILLIS;

export function monthToString(date: Date) {
	switch (date.getMonth() + 1) {
		case 1: {
			return 'Janvier';
		}
		case 2: {
			return 'Février';
		}
		case 3: {
			return 'Mars';
		}
		case 4: {
			return 'Avril';
		}
		case 5: {
			return 'Mai';
		}
		case 6: {
			return 'Juin';
		}
		case 7: {
			return 'Juillet';
		}
		case 8: {
			return 'Aout';
		}
		case 9: {
			return 'Septembre';
		}
		case 10: {
			return 'Octobre';
		}
		case 11: {
			return 'Novembre';
		}
		case 12: {
			return 'Décembre';
		}
	}
}

export function extractYear(dateText: string): string {
	return `${dayjs(dateText).get('year')}`;
}

export function secondsToString(seconds: number | undefined): string {
	if (seconds === undefined) {
		return '0';
	} else {
		const h = Math.floor(seconds / 3600);
		const m = Math.floor((seconds % 3600) / 60);
		const s = seconds % 60;

		const parts = [];
		if (h > 0) parts.push(`${h} h`);
		if (m > 0) parts.push(`${m} m`);
		if (s > 0 || parts.length === 0) parts.push(`${s} s`);

		return parts.join(' ');
	}
}

export function isValidBirthDate(date: ISODateString): boolean {
	const candidate = DateTime.fromISO(date);
	const birthDateLowerLimit = DateTime.now().minus({ years: 130 });
	const birthDateUpperLimit = DateTime.now().minus({ years: 10 });
	return candidate > birthDateLowerLimit && candidate < birthDateUpperLimit;
}

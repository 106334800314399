import { watchImmediate } from '@vueuse/core';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { DateTime } from 'luxon';
import { storeToRefs } from 'pinia';
import { lastValueFrom } from 'rxjs';
import { tap } from 'rxjs/operators';
import { watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useTheme as useVuetifyTheme } from 'vuetify';

import { useHttpCache, useObservable, useTheme } from '@silae/composables';
import { Optional } from '@silae/helpers';
import { AUTH_API, UserDTO, useBackendHttpService } from '~/api';
import { useToasts } from '~/composables';
import { SignInRoute } from '~/pages/sign-in/sign-in.route';
import { useLoadingService, useStorageService } from '~/services';
import { Clearable, useQaFolderFormatsStore } from '~/stores';
import { useAuthenticationStore } from '~/stores/authentication.store';
import { useBackendAppStore } from '~/stores/backend-app.store';
import { useProductBoardStore } from '~/stores/roadmap/product-board.store';

export function initBackend(): void {
	const { setBackendURL, setWithCredentials } = useBackendHttpService();

	setBackendURL(import.meta.env.QA2_BACKEND_URL);
	setWithCredentials(true);
}

export function initRefreshToken(): void {
	const { requestInterceptors } = useBackendHttpService();
	const { refreshAuthUsingHeadlessB2C$, getRefreshTokenFromLocalStorage } = useAuthenticationStore();
	const { isAuthenticated, accessTokenExpirationTime } = storeToRefs(useAuthenticationStore());
	const { cache$, clearCache } = useHttpCache<string, Optional<UserDTO>>();

	async function refreshInterceptor(config: AxiosRequestConfig<any>): Promise<AxiosRequestConfig<any>> {
		if (config.url?.includes(AUTH_API)) {
			return Promise.resolve(config);
		}

		const hasAccessTokenExpired = () => {
			if (accessTokenExpirationTime.value == undefined) return true;
			return accessTokenExpirationTime.value < DateTime.now().toMillis();
		};

		if (isAuthenticated.value && hasAccessTokenExpired() && config.withCredentials && !!getRefreshTokenFromLocalStorage()) {
			await lastValueFrom(cache$('refresh-token-cache', refreshAuthUsingHeadlessB2C$()).pipe(tap(() => clearCache())));
		}

		return Promise.resolve(config);
	}

	// refreshInterceptor as some weird axios type mismatch error
	// cast as any for a quick and dirty fix
	// TODO try to understand if it is an issue with axios typing or multiple axios deps collision due to having both 'axios' and 'axios-observable'
	requestInterceptors.use(refreshInterceptor as any);
}

export function initSessionLostRedirection(): void {
	const { warning: showWarningToast } = useToasts();
	const { t } = useI18n();
	const { push, currentRoute } = useRouter();
	const { responseInterceptors } = useBackendHttpService();
	const { isAuthenticated } = storeToRefs(useAuthenticationStore());

	responseInterceptors.use(
		response => response,
		(error: AxiosError) => {
			const isPublic = currentRoute.value?.matched.some(match => match.meta?.public);

			if (error.response && error.response.status === 401 && !isPublic && isAuthenticated.value) {
				showWarningToast({
					title: t('common.feedback.session_lost'),
					text: t('common.feedback.session_lost_description')
				});
				push(SignInRoute);
			}
			return Promise.reject(error);
		}
	);
}

export function initErrorInterceptors(): void {
	const { responseInterceptors } = useBackendHttpService();
	const { error: showErrorToast } = useToasts();
	const { t } = useI18n();
	responseInterceptors.use(
		response => response,
		(error: AxiosError<ArrayBuffer>) => {
			if (error.response?.config?.responseType === 'arraybuffer') {
				error.response.config.responseType = 'json';
				error.response.data = decodeArrayBufferIntoJson(error);
			}
			if (error.response?.status === 401) {
				return Promise.reject(error);
			}
			let textError = 'common.feedback.error.title';
			if (error.response?.status !== undefined) {
				textError = `common.feedback.${error.response.status}`;
			}
			if (error.code !== 'ERR_CANCELED') {
				showErrorToast({
					title: t('common.feedback.error.title'),
					text: t(textError)
				});
			}
			return Promise.reject(error);
		}
	);
}

function decodeArrayBufferIntoJson(error: AxiosError<ArrayBuffer, any>) {
	return JSON.parse(new TextDecoder().decode(error.response.data));
}

export function initLoadingService(): void {
	const { requestInterceptors, responseInterceptors } = useBackendHttpService();
	const { addRunningCall, removeRunningCall } = useLoadingService();

	requestInterceptors.use(request => {
		addRunningCall();
		return request;
	});

	responseInterceptors.use(
		response => {
			removeRunningCall();
			return response;
		},
		(error: Error) => {
			removeRunningCall();
			return Promise.reject(error);
		}
	);
}

/***
 set storage ID on sign in
 */
export function initStorageService(): void {
	const { isAuthenticated, principal } = storeToRefs(useAuthenticationStore());
	const storageService = useStorageService();
	watchImmediate(isAuthenticated, isAuthenticated => {
		if (isAuthenticated) {
			storageService.setId(principal.value?.login);
		}
	});
}

// const EMPLOYEE_SELECTION_KEY = "employee-company-selection";
// const MANAGER_SELECTION_KEY = "manager-company-selection";
// const ADMIN_SELECTION_KEY = "admin-company-selection";
// const ACTIVE_ROLE_KEY = "active-role";

// export function initLocalStorageSynchronization(): void {
// 	// selection
// 	const selectionStore = useCompanySelectionStore();
// 	const { employeeCompanyId, managerCompaniesIds, adminCompanyId } = storeToRefs(selectionStore);
// 	useLocaleStorageSynchronization(employeeCompanyId, EMPLOYEE_SELECTION_KEY, selectionStore.selectEmployeeCompany);
// 	useLocaleStorageSynchronization(managerCompaniesIds, MANAGER_SELECTION_KEY, selectionStore.selectManagerCompanies);
// 	useLocaleStorageSynchronization(adminCompanyId, ADMIN_SELECTION_KEY, selectionStore.selectAdminCompany);
//
// 	// role
// 	const rolesStore = useRolesStore();
// 	const { activeRole } = storeToRefs(rolesStore);
// 	useLocaleStorageSynchronization(activeRole, ACTIVE_ROLE_KEY, rolesStore.setActiveRole);
// }

/***
 Check whether backend is up or not
 */
export function useHealthCheck(): void {
	const { trigger } = useObservable();
	trigger(useBackendAppStore().fetchCheckHealth$());
}

/***
 Ensure Vuetify theme is sync with app theme
 */
export function initThemeSync(): void {
	const { theme } = useTheme();
	const vuetifyTheme = useVuetifyTheme();
	watchImmediate(theme, theme => (vuetifyTheme.global.name.value = theme));
}

/***
 clear all necessary stores on sign out
 */
export function clearStateOnSignOut(): void {
	const authenticationStore = useAuthenticationStore();
	const { isAuthenticated } = storeToRefs(authenticationStore);
	const clearables: Clearable[] = [authenticationStore, useStorageService(), useProductBoardStore(), useQaFolderFormatsStore()];

	watch(isAuthenticated, (isAuthenticated, wasAuthenticated) => {
		if (wasAuthenticated && !isAuthenticated) {
			clearables.forEach(store => store.clear());
		}
	});
}

/***
 Redirect to home page when user updates its role or company selection and finds herself on a forbidden route
 */
// export function useForbiddenRouteRedirect() {
// 	const { isForbidden } = useGuardedRoutes();
// 	const { activeRole } = storeToRefs(useRolesStore());
// 	const { employeeCompanyId, adminCompanyId } = storeToRefs(useCompanySelectionStore());
// 	const { push, currentRoute } = useRouter();
//
// 	watchImmediate([employeeCompanyId, adminCompanyId, activeRole], ([newCompanyId, newAdminCompanyId, newRole]) => {
// 		if ((newCompanyId || newAdminCompanyId || newRole) && isForbidden(currentRoute.value)) push(HomeRoute);
// 	});
// }

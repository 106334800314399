import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { defaultLayoutComponents } from '~/pages/layout';
import { RouteKeys } from '~/router/routes.domain';
import { useRolesStore } from '~/stores';

export const qaTestCaseRoute: RouteRecordRaw = {
	path: ':testCaseId',
	name: RouteKeys.QA2_TEST_CASE,
	components: {
		default: () => import('./TestCaseView.vue'),
		...defaultLayoutComponents
	},
	meta: {
		requiresAuth: true,
		isForbidden: () => {
			const { isQA } = storeToRefs(useRolesStore());
			return computed(() => !isQA.value);
		}
	}
};

export const qaTestCaseHomeRoute = {
	path: 'test-case',
	name: RouteKeys.QA2_TEST_CASE_HOME,
	components: {
		default: () => import('./TestCaseView.vue'),
		...defaultLayoutComponents
	},
	children: [qaTestCaseRoute],
	meta: {
		requiresAuth: true,
		isForbidden: () => {
			const { isAdmin } = storeToRefs(useRolesStore());
			return computed(() => !isAdmin.value);
		},
		label: 'qa.testCase.navigation',
		isSubEntry: true,
		order: 3
	}
};

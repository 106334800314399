import { ListEntry, StringProperty } from '@silae/helpers';

export type TestResultPatternDTO = {
	id?: number;
	parentId?: number;
	name: string;
	pattern: string;
	function: string;
	standalone: boolean;
};

export type MethodParameterDTO = {
	name: string;
	type: string;
};

export type MethodDTO = {
	value: string;
	label: string;
	className: string;
	parameters: MethodParameterDTO[];
};
/*
 * user dto reference date based on folder reference mont period
 */
export type ReferencedDate = {
	reference: string;
	/**
	 * number of month before or after reference.
	 * not used if reference is inPast or inFuture
	 */
	value: number;
};

export type DateFormat = {
	type: string;
	managerReference: string;
};

export type FolderFormat = {
	cleanLeave: boolean;
	reference: string;
	modules: string[];
};

export type LeaveFormat = {
	reference: string;
	startDate: ReferencedDate;
	endDate: ReferencedDate | undefined;
	period: string;
	periodQuantity: number; //Only for FULL_DAYS and HOURS
	employeeReference: string | undefined;
	validatorReference: string | undefined;
	state: string | undefined;
};

export type PrerequisiteFolderFormat = {
	leaves: LeaveFormat[];
	users: UserFormat[];
	folderFormats: FolderFormat;
};

export type PrerequisiteFolders = {
	format: string;
	folders: PrerequisiteFolderFormat[];
	multiFolderUserFormats: MultiFolderUserFormat[];
};

export type MultiFolderUserFormat = {
	userReferences: Record<number, string>;
};

export enum EmployeeRole {
	EMPLOYEE = 'EMPLOYEE',
	MANAGER = 'MANAGER',
	ADMIN = 'ADMIN'
}

export type FolderRole = {
	folderReference: string;
	roles: EmployeeRole[];
};

export type UserFormat = {
	folderReferences: FolderRole[];
	reference: string;
	managerReference: string | undefined;
	details: UserFormatDetails | undefined;
	employee: boolean;
	contact: boolean;
	adminRef: boolean;
};

export type UserFormatDetails = {
	firstName: string | undefined;
	lastName: string | undefined;
	contractType: string | undefined;
	endContractDate: ReferencedDate | undefined;
	startContractDate: ReferencedDate | undefined;
};

export const referenceListEntries: ListEntry[] = [
	{ value: 'A', label: 'A' },
	{ value: 'B', label: 'B' },
	{ value: 'C', label: 'C' },
	{ value: 'D', label: 'D' },
	{ value: 'E', label: 'E' },
	{ value: 'F', label: 'F' },
	{ value: 'G', label: 'G' },
	{ value: 'H', label: 'H' },
	{ value: 'I', label: 'I' },
	{ value: 'J', label: 'J' }
];
export const referenceManagerListEntries: ListEntry[] = [...referenceListEntries, { value: undefined, label: 'Pas de manager' }];

export type PrerequisiteFormatDTO = {
	leaves: LeaveFormat[];
	users: UserFormat[];
	folders: FolderFormat[];
};

export type STTestStepCounters = {
	testStepCountOK: number;
	testStepCountIgnore: number;
	testStepCount: number;
	testStepCountKO: number;
	testStepCountBlocked: number;
};

export type STTestCaseExecution = STTestStepCounters & {
	id: number | undefined;
	testCampaignId: number;
	name: string;
	prerequisite: string;
	result: string;
	resultComment: string;
	testCaseId: number;
	executionDuration: number;
	azureProjectName: string | undefined;
	azureBuildId: string | undefined;
	azureJobId: string | undefined;
	azureTaskId: string | undefined;
};

export function getAzureJobLinkForTestCase(testCase: STTestCaseExecution | undefined): string {
	if (
		testCase?.azureProjectName !== undefined &&
		testCase?.azureBuildId !== undefined &&
		testCase?.azureJobId !== undefined &&
		testCase?.azureTaskId !== undefined
	) {
		return `https://dev.azure.com/silae/${testCase.azureProjectName}/_build/results?buildId=${testCase.azureBuildId}&view=logs&j=${testCase.azureJobId}&t=${testCase.azureTaskId}`;
	} else {
		return undefined;
	}
}

export type STTestStepExecution = {
	id: number | undefined;
	testCaseId: number;
	testCaseExecutionId: number;
	testStepId: number;
	action: string;
	expectedResult: string;
	stepIndex: number;
	result: string;
	resultComment: string;
};
export type STTestCampaign = STTestStepCounters & {
	id: number | undefined;
	folderId: number;
	name: string;
	result: string;
	createdBy: string | undefined;
	startExecutionDate: Date | undefined;
	lastExecutionDate: Date | undefined;
	testDuration: number | undefined;
	azureProgress: BuildStatus | undefined;
	azureProjectName: string | undefined;
	azureBuildId: string | undefined;
	testUrl: string | undefined;
	testGitBranch: string | undefined;
	testScheduling: string | undefined;
	azurePipelineRunBy: string | undefined;
};

export enum BuildStatus {
	all = 'all',
	cancelling = 'cancelling',
	completed = 'completed',
	inProgress = 'inProgress',
	none = 'none',
	notStarted = 'notStarted',
	postponed = 'postponed'
}

export function getBuildStatusColor(result: BuildStatus): string {
	switch (result) {
		case 'completed': {
			return 'success';
		}
		case BuildStatus.all:
		case BuildStatus.none:
		case BuildStatus.notStarted:
		case BuildStatus.inProgress:
			return 'info';
		default: {
			return 'neutral';
		}
	}
}

export function getTestResultSorter<T>(property: StringProperty<T>): (a: T, b: T) => number {
	return (a: T, b: T) => {
		const valueA: string = (a[property] as string) ?? 'FAILURE';
		const valueB: string = (b[property] as string) ?? 'FAILURE';
		const valueANumber = getTestResultIndex(valueA);
		const valueBNumber = getTestResultIndex(valueB);
		return valueANumber - valueBNumber;
	};
}

export function getTestResultIndex(result: string): number {
	switch (result) {
		case 'FAILURE': {
			return 0;
		}
		case 'BLOCKED': {
			return 1;
		}
		case 'UNTESTABLE': {
			return 2;
		}
		case 'READY': {
			return 3;
		}
		case 'RUNNING': {
			return 4;
		}
		case 'SUCCESS': {
			return 5;
		}
		default: {
			return 0;
		}
	}
}

export function getTestResultColor(result: string): string {
	switch (result) {
		case 'SUCCESS': {
			return 'success';
		}
		case 'RUNNING':
		case 'READY': {
			return 'info';
		}
		case 'FAILURE': {
			return 'error';
		}
		case 'BLOCKED': {
			return 'warning';
		}
		default: {
			return 'neutral';
		}
	}
}

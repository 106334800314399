import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { defaultLayoutComponents } from '~/pages/layout';
import { RouteKeys } from '~/router/routes.domain';
import { useRolesStore } from '~/stores';

export const TeamRoute: RouteRecordRaw = {
	path: 'team/:teamId',
	name: RouteKeys.PAY_GEN_TEAM,
	components: {
		default: () => import('./TeamView.vue'),
		...defaultLayoutComponents
	},
	meta: {
		requiresAuth: true,
		isForbidden: () => {
			const { isUser } = storeToRefs(useRolesStore());
			return computed(() => !isUser.value);
		}
	}
};

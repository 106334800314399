import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import { defaultLayoutComponents } from '~/pages/layout';
import { RouteKeys } from '~/router/routes.domain';
import { useRolesStore } from '~/stores';

export const qaTestCampaignDetailsRoute = {
	path: 'test-campaign/:testCampaignId',
	name: RouteKeys.QA_TEST_CAMPAIGN_DETAILS,
	components: {
		default: () => import('./TestCampaignDetail.vue'),
		...defaultLayoutComponents
	},
	meta: {
		requiresAuth: true,
		isForbidden: () => {
			const { isQA } = storeToRefs(useRolesStore());
			return computed(() => !isQA.value);
		},
		label: 'qa.testCampaign.navigation',
		order: 6
	}
};

export const qaTestCaseExecutionRoute = {
	path: 'test-case-execution/:testCaseExecutionId',
	name: RouteKeys.QA_TEST_CASE_EXECUTION_DETAILS,
	components: {
		default: () => import('./TestCaseExecutionDetail.vue'),
		...defaultLayoutComponents
	},
	meta: {
		requiresAuth: true,
		isForbidden: () => {
			const { isQA } = storeToRefs(useRolesStore());
			return computed(() => !isQA.value);
		},
		label: 'qa.testCampaign.navigation',
		order: 6
	}
};

export const qaTestStepExecutionRoute = {
	path: 'test-case-execution/:testCaseExecutionId/step/:testStepExecutionId',
	name: RouteKeys.QA_TEST_STEP_EXECUTION_DETAILS,
	components: {
		default: () => import('./TestCaseExecutionDetail.vue'),
		...defaultLayoutComponents
	},
	meta: {
		requiresAuth: true,
		isForbidden: () => {
			const { isQA } = storeToRefs(useRolesStore());
			return computed(() => !isQA.value);
		},
		label: 'qa.testCampaign.navigation',
		order: 6
	}
};

export const qaTestCampaignHomeRoute = {
	path: 'test-campaign',
	name: RouteKeys.QA_TEST_CAMPAIGN,
	components: {
		default: () => import('./TestCampaignView.vue'),
		...defaultLayoutComponents
	},
	meta: {
		requiresAuth: true,
		isForbidden: () => {
			const { isQA } = storeToRefs(useRolesStore());
			return computed(() => !isQA.value);
		},
		label: 'qa.testCampaign.navigation',
		isSubEntry: true,
		order: 1
	}
};

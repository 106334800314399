import { Clearable } from '~/stores';
import { computed, ComputedRef, ref, Ref } from 'vue';
import { defineStore } from 'pinia';
import { useHttpCache } from '@silae/composables';
import { Observable, tap } from 'rxjs';
import { getAllFolderFormatValue } from '~/api/qaauto/qa-auto-api';
import { ListEntry } from '@silae/helpers';

const singletonFolderFormatReferentialUniqueKey = 'qa_folder_format';

export type QaFolderFormatsStore = Clearable & {
	loadFolderFormat$: () => Observable<Array<string>>
	folderFormats: ComputedRef<Array<string>>;
	folderFormatEntries: ComputedRef<Array<ListEntry>>;
};

export const useQaFolderFormatsStore = defineStore<'qaFolderFormatsStore', QaFolderFormatsStore>('qaFolderFormatsStore', () => {
	const { cache$: cacheMasterObjectives$, clearCache } = useHttpCache<string, Array<string>>();
	const _folderFormats: Ref<Array<string>> = ref([]);
	const _loadFolderFormat$: () => Observable<Array<string>> = () => {
		return cacheMasterObjectives$(
			singletonFolderFormatReferentialUniqueKey,
			getAllFolderFormatValue().pipe(tap(res => (_folderFormats.value = res ?? [])))
		);
	};
	const clear = () => {
		clearCache();
	};

	return {
		clear,
		loadFolderFormat$: _loadFolderFormat$,
		folderFormats: computed(() => _folderFormats.value),
		folderFormatEntries: computed(() => _folderFormats.value.map(format => {
			return {
				value: format,
				label: format
			} as ListEntry;
		}))
	};
});
